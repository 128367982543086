var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticStyle: { padding: "20px", "box-sizing": "border-box" }
    },
    [
      _c(
        "c-box",
        [
          (_vm.form.approvalStatus != 0
          ? false
          : true)
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleApproval }
                },
                [_vm._v(" 审核 ")]
              )
            : _vm._e(),
          (_vm.form.approvalStatus != 0
          ? false
          : true)
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleTurnDown }
                },
                [_vm._v(" 驳回 ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.close }
            },
            [_vm._v(" 关闭 ")]
          )
        ],
        1
      ),
      _c(
        "c-box",
        {
          staticStyle: { overflow: "auto" },
          attrs: { height: "calc(100vh - 60px)" }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                size: _vm.size,
                "label-width": _vm.labelWidth
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateSubmit($event)
                }
              }
            },
            [
              _c("c-form-row", {
                attrs: { list: _vm.models, readOnly: _vm.readOnly },
                scopedSlots: _vm._u([
                  {
                    key: "baseArea",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          _vm._b(
                            {},
                            "el-form-item",
                            scope.data.itemProps,
                            false
                          ),
                          [
                            _c("codeArea", {
                              attrs: {
                                code: _vm.form.baseArea,
                                detail: _vm.form.address
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "certificateImages",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          _vm._b(
                            {},
                            "el-form-item",
                            scope.data.itemProps,
                            false
                          ),
                          [
                            _c(
                              "div",
                              { key: _vm.img, staticClass: "block" },
                              _vm._l(_vm.form.certificateImages, function(
                                img,
                                index
                              ) {
                                return _c("el-image", {
                                  key: index,
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    "margin-right": "10px"
                                  },
                                  attrs: {
                                    src: _vm.ossUrl + img,
                                    fit: "fill",
                                    "preview-src-list": Array(_vm.ossUrl + img)
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "qualificationImages",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          _vm._b(
                            {},
                            "el-form-item",
                            scope.data.itemProps,
                            false
                          ),
                          [
                            _c(
                              "div",
                              { key: _vm.img, staticClass: "block" },
                              _vm._l(_vm.form.qualificationImages, function(
                                img,
                                index
                              ) {
                                return _c("el-image", {
                                  key: index,
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    "margin-right": "10px"
                                  },
                                  attrs: {
                                    src: _vm.ossUrl + img,
                                    fit: "fill",
                                    "preview-src-list": Array(_vm.ossUrl + img)
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("FormDialogPass", {
        ref: "FormDialogPass",
        on: { refresh: _vm.getData }
      }),
      _c("FormDialogReject", {
        ref: "FormDialogReject",
        on: { refresh: _vm.getData }
      }),
      _c("ListDialog", { ref: "ListDialog", on: { refresh: _vm.getData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
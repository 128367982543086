/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-13 17:50:44
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import {
genModels } from
'@/plugins/widget/c-form/rules';
import {
auditStatus,
payCatalog,
getLabel } from
'@/utils/options';

export var models = genModels(function (genRules, ctx) {return [{
    span: 10,
    kname: 'doctorName',
    itemProps: {
      label: '医生姓名：' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'image',
    itemProps: {
      label: '头像：' },

    component: {
      name: 'c-image' } },


  {
    span: 10,
    kname: 'doctorPhone',
    itemProps: {
      label: '手机号码：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'approvalStatus',
    itemProps: {
      label: '状态：' },

    component: {
      name: 'text',
      textStyle: {
        fontWeight: 'bold',
        color: '#618CFF' } },


    valueFilter: function valueFilter(v) {
      return getLabel(auditStatus, v);
    } },

  {
    span: 10,
    kname: 'birthday',
    itemProps: {
      label: '生日：' },

    component: {
      name: 'text' } },


  {
    span: 6,
    kname: 'departmentName',
    itemProps: {
      label: '科室名称：' },

    component: {
      name: 'text' } },


  {
    span: 6,
    kname: 'jobTitle',
    itemProps: {
      label: '职称：' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'baseArea',
    itemProps: {
      label: '位置：',
      rules: [genRules('require')],
      prop: 'baseArea' } },


  {
    span: 6,
    kname: 'createAt',
    itemProps: {
      label: '申请时间：' },

    component: {
      name: 'text' } },


  {
    span: 6,
    kname: 'hospitalName',
    itemProps: {
      label: '医院名称：' },

    component: {
      name: 'text' } },


  {
    span: 10,
    kname: 'recommendCode',
    itemProps: {
      label: '推荐号码：' },

    component: {
      name: 'text' } },


  {
    span: 6,
    kname: 'certificateNumber',
    itemProps: {
      label: '执业证书编号：' },

    component: {
      name: 'text' } },


  {
    span: 6,
    kname: 'remark',
    itemProps: {
      label: '备注说明：' },

    component: {
      name: 'text' } },


  {
    kname: 'certificateImages',
    itemProps: {
      label: '执业证书照片：' },

    component: {
      name: 'c-image' } },


  {
    kname: 'qualificationImages',
    itemProps: {
      label: '资格证书照片：' },

    component: {
      name: 'c-image' } }];});





export var formData = function formData() {return {
    list: [{}, {}] };};